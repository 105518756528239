import ROUTE_NAME from '@/router/routeName.js'
export default {
	data() {
		return {
			ROUTE_NAME
		}
	},
  methods: {
		scrollIntoViewWithOffset (val) {
			if (val) {
				const el = document.querySelector(val)
				window.location.hash = val
				if (el) {
					window.scrollTo({
						behavior: 'smooth',
						top:
							el.getBoundingClientRect().top -
							document.body.getBoundingClientRect().top -
							150,
					})
				}
			}
		},
		openLink (link) {
			const selector = link.to?.hash
			if (selector) {
				this.scrollIntoViewWithOffset(selector)
			}
			if (link.href) window.open(link.href, link.target || '');
		},

		handleLogoClick () {
			if (this.$route.name !== ROUTE_NAME.INDEX) this.$router.push('/')
			window.scrollTo(0, 0)
		},

		handleRouterLinkClick () {
			window.scrollTo(0, 0)
		}
	}
}