<template>
  <div class="layout">
    <div class="layout-main">
      <div class="wrapper-base">
        <div class="line-temp line-global section-inside line_mobile-large">
          <div class="cols-clear">
            <div class="text-container text-center">
              <h1 class="title main-title text-center">
                Аналитическая платформа <br/>
								повышения эффективности маркетинга <br/>
								для eСommerce
              </h1>
            </div>
            <div class="line-large line_mobile-s description">
              <div class="description__content paragraph-mega color_dark text-center">
                Актуальные данные по всем ключевым метрикам, агрегированным
								по&nbsp;структуре каталога интернет-магазина/маркетплейса в одном интерфейсе
              </div>
            </div>
            <div class="demo-btn-wrapper line-large text-center line_mobile-medium-max">
              <DemoBtn />
            </div>
						<div class="line-mega line_mobile-mega-plus text-center index-preview">
							<picture>
								<source
									srcset="@/assets/img/landing/index-preview-mobile.svg"
									type="image/svg+xml"
									media="(max-width: 480px)"
								/>
								<img src="@/assets/img/landing/index-preview.svg" alt="Dataforce" loading="lazy" class="img-content">
							</picture>
						</div>
          </div>
        </div>
      </div>
			<div class="line-section-sm line_mobile-large">
				<div class="wrapper-base">
					<ClientsSlider />
				</div>
			</div>
			<div class="wrapper-base line_mobile-mega" id="capabilities">
				<div class="line-section-sm line_mobile-m">
					<h2 class="title text-center">Возможности</h2>
					<div class="line-content-md line_mobile-large text-center">
						<Capabilities />
					</div>
					<div class="line-global line_mobile-large text-center cols _align-center">
						<a
							:href="`${config.api.dataforce}/help/df_presentation.pdf`"
							target="_blank"
							class="more-btn__wrapper"
						>
							<v-button
								class="more-btn landing-btn"
								name="Узнать больше"
								dataforce
								icon="arrow_forward"
								positionIcon="right"
                size="large"
							/>
						</a>
					</div>
				</div>
			</div>

			<div class="wrapper-base">
				<div class="line-section-large line_mobile-mega">
					<h3 class="title sm-title text-center trust-title">Нам доверяют лидеры еCommerce</h3>
					<div class="line-global line_mobile-large">
						<Features />
					</div>
				</div>
			</div>

			<div class="wrapper-base" id="data">
				<div class="line-section-large line_mobile-section">
					<h2 class="title text-center">Все данные и отчеты в одной платформе</h2>
				</div>
			</div>
			<div class="line-global line_mobile-large cols _justify-center">
				<img src="@/assets/img/landing/all-data-bg.svg" alt="Данные и отчеты" loading="lazy" class="img-content m-auto">
			</div>

			<div class="wrapper-base" id="cost">
				<div class="line-section-large line_mobile-mega">
					<div class="cost-block">
						<div class="cost-block__content">
							<h2 class="title">Стоимость</h2>
							<div class="cost-block__price-wrapper line-large line_mobile-medium">
								<p class="cost-block__price">от 50 000 ₽</p>
								<p class="cost-block__eta">Сроки внедрения: 1 неделя</p>
							</div>
							<div class="cost-block__client line-large line_mobile-medium">Подходит ecommerce проектам с 5000-10000 SKU</div>
							<v-button
								class="line-large line_mobile-medium start-btn landing-btn"
								:to="{ name: ROUTE_NAME.DEMO }"
								name="Начать сейчас"
								dataforce
                size="large"
							/>
						</div>
						<div class="cost-block__img">
							<img src="@/assets/img/landing/index-preview.svg" alt="Скриншот" loading="lazy" class="img-content">
						</div>
					</div>
				</div>
			</div>

			<div class="wrapper-base" id="publications">
				<div class="line-section-sm line_mobile-section">
					<Publications />
				</div>
			</div>

			<div class="line-section-medium line_mobile-mega dataforce-block cols _align-middle _justify-center">
				<div class="wrapper-base">
					<h2 class="title title-large dataforce-block__title text-center">Узнайте, чем Dataforce полезен для&nbsp;вашей&nbsp;компании</h2>
					<div class="line-regular text-center line_mobile-medium">
						<DemoBtn />
					</div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import Capabilities from './components/capabilities/Capabilities.vue'
import Features from './components/features/features.vue'
import DemoBtn from './components/demo-btn/DemoBtn.vue'
import ClientsSlider from './components/clients-slider/ClientsSlider.vue'
import Publications from './components/publications/publications.vue'
import scroll from '@/landing/helpers/scroll.js'
import ROUTE_NAME from '@/router/routeName.js'
export default {
  name: 'index-new',
	mixins: [scroll],
	data () {
		return {
			ROUTE_NAME
		}
	},
  metaInfo() {
    return {
      title: 'DATAFORCE — система аналитики данных для роста продаж в eCommerce',
      meta: [
        {
          name: 'description',
          content: 'Уникальный инструмент аналитики на стыке «маркетинг — продукт — коммерция» для роста продаж в eCommerce  ✅ Готовый инструмент для онлайн-ритейла, аналитика от спроса до доставки и выручки с разбивкой по товарным подкатегориям'
        }
      ]
    }
  },

	components: {
		Capabilities,
		Features,
		DemoBtn,
		ClientsSlider,
		Publications
	},

	computed: {
		config () {
			return this.$store.state.config
		},
		routeHash() {
			return this.$route.hash
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.landing-btn {
	height: 52px;
}
.start-btn {
	width: 170px;
}
.more-btn {
	width: fit-content;
	min-width: 208px;
	&__wrapper {
		text-decoration: none;
	}
}
.cost-block {
	background: #FFF9EC;
	border-radius: 16px;
	padding: 70px 80px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: relative;
	overflow: hidden;
	min-height: 483px;

	@media all and (max-width: 1023px) {
		grid-template-columns: 1fr;
		padding: 40px 50px;
		min-height: 350px;
	}

	@include tablets {
		min-height: 300px;
	}

	@include phones {
		padding: 48px;
	}

	&__content {
		position: relative;

		&:after {
			content: "";
			display: block;
			position: absolute;
			bottom: -27%;
			right: -8%;
			width: 144px;
			height: 144px;
			background-image: url('~@/assets/img/landing/bg-dots-2.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			opacity: .4;

			@include sm-desktops {
				bottom: -15%;
			}

			@include tablets {
				top: 0;
				bottom: 0;
				margin: auto;
				right: -45%;
			}

			@include phones {
				right: -55%;
			}
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 15%;
			left: -32%;
			width: 120px;
			height: 120px;
			background-image: url('~@/assets/img/landing/bg-dots-2.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			opacity: .4;
			@media all and (max-width: 1244px) {
				left: -40%;
			}
			@media all and (max-width: 1038px) {
				left: -50%;
			}
			@include sm-desktops {
				left: -30%;
			}
			@include tablets {
				left: -60%;
			}
			@include phones {
				display: none;
			}
		}
	}

	&__price-wrapper {
		position: relative;
		padding-left: 20px;

		&:before {
			content: "";
			width: 4px;
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			background: #0E32F5;
			border-radius: 100px;
		}
	}

	&__price {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 28px;
		line-height: 129%;
		color: #0A1830;
		margin-bottom: 4px;

		@include phones {
			font-size: 28px;
		}
	}

	&__eta {
		font-size: 20px;
		line-height: 140%;
		color: #9D99AA;

		@include phones {
			font-size: 16px;
			font-weight: 500;
		}
	}

	&__client {
		color: #455165;
		@include phones {
			font-size: 14px;
		}
	}

	&__img {
		@media all and (max-width: 1023px) {
			display: none;
		}
		img {
			position: absolute;
			bottom: -5%;
			right: -20%;
			width: 724px;
			height: 448px;
			border-radius: 8px;
			object-fit: contain;

			@media all and (max-width: 1244px) {
				right: -30%;
			}

			@media all and (max-width: 1150px) {
				width: 620px;
				height: 380px;
				bottom: 0;
			}
		}
	}
}
.dataforce-block {
	background: #F6F5FF;
	min-height: 448px;
	position: relative;
	overflow: hidden;

	@include phones {
		min-height: 268px;

		&:after {
			content: "";
			display: block;
			position: absolute;
			bottom: -5%;
			right: -2%;
			width: 180px;
			height: 140px;
			background-image: url('~@/assets/img/landing/dataforce-block-bg-right-mobile.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			opacity: .7;
		}
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -21%;
    left: -4%;
		width: 230px;
		height: 280px;
		background-image: url('~@/assets/img/landing/bg-dots-2.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		opacity: .8;

		@include tablets {
			left: -20%;
		}
		@include phones {
			width: 145px;
			height: 176px;
			left: -26%;
    	top: -42%;
		}
	}

	.wrapper-base {
		position: relative;

		&:before {
			content: "";
			display: block;
			position: absolute;
			bottom: -85%;
			left: 3%;
			width: 80%;
			height: 330px;
			background-image: url('~@/assets/img/landing/dataforce-block-bg-bottom.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			@include phones {
				display: none;
			}
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: -55%;
			right: -15%;
			width: 380px;
			height: 352px;
			background-image: url('~@/assets/img/landing/dataforce-block-bg-right.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;

			@include tablets {
				right: -20%;
			}

			@include phones {
				display: none;
			}
		}
	}

	&__title {
		max-width: 52%;
		line-height: 130%;
		margin-left: auto;
		margin-right: auto;

		@include sm-desktops {
			font-size: 36px;
			max-width: 70%;
		}

		@include phones {
			font-size: 22px;
			max-width: 90vw;
		}
	}

	.demo-btn {
		@include phones {
			max-width: 220px;
		}
	}
}
.description {
	position: relative;
	z-index: 1;

	&__content {
		margin: 0 auto;
		max-width: 70%;

		@include sm-desktops {
			max-width: 90%;
		}

		@include phones {
			line-height: 143%;
			max-width: none;
		}
	}

	&:after {
		content: "";
		display: block;
		width: 120px;
		height: 120px;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		right: -14%;
		background-image: url('~@/assets/img/landing/bg-dots-2.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 0;

		@media all and (max-width: 1530px) {
			right: -5%;
		}

		@include sm-desktops {
			display: none;
		}
	}
}
.index-preview {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		z-index: 1;
		position: relative;

		@include sm-desktops {
			max-width: 90vw;
		}

		@include phones {
			max-width: 100%;
		}
	}

	&:before {
		content: "";
		display: block;
		width: 622px;
		height: 516px;
		position: absolute;
		top: -41%;
		left: -7%;
		background-image: url('~@/assets/img/landing/index-bg-1.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 0;

		@include sm-desktops {
			width: 300px;
			height: 250px;
			top: -30%;
			left: -10%;
		}

		@include phones {
			width: 228px;
			height: 189px;
			left: -15%;
			top: -39%;
		}
	}

	&:after {
		content: "";
		display: block;
		width: 325px;
		height: 325px;
		position: absolute;
		top: -23%;
		right: 2%;
		background-image: url('~@/assets/img/landing/index-bg-2.svg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 0;

		@include sm-desktops {
			width: 200px;
			height: 200px;
			top: -15%;
			right: -5%;
		}

		@include phones {
			width: 119px;
			height: 119px;
			top: -18%;
    	right: -6%;
		}
	}
}
.demo-btn-wrapper {
	position: relative;

	@include phones {
		&:after {
			content: "";
			display: block;
			width: 44px;
			height: 44px;
			position: absolute;
			right: 12%;
    	bottom: -17%;
			background-image: url('~@/assets/img/landing/bg-dots-2.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			z-index: 0;
		}
	}
}

.trust-title {
	@include phones {
		max-width: 90%;
		margin: auto;
	}
}
</style>
