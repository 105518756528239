var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attention-slider-wrapper"},[_c('VueSlickCarousel',{staticClass:"attention-slider",attrs:{"arrows":false,"dots":false,"infinite":true,"autoplay":true,"speed":2500,"autoplaySpeed":2500,"cssEase":'ease-in-out',"slidesToShow":6,"responsive":[
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 410,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
		],"pauseOnHover":true,"swipe":false}},_vm._l((_vm.partners),function(partner){return _c('div',{key:partner.title,staticClass:"attention-item"},[_c('img',{attrs:{"src":_vm.getImgUrl(`partners/${partner.imgName}.svg`)}})])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }