<template>
	<ul class="feature-list">
		<li
			v-for="item in points"
			:key="item.id"
			class="feature-item cols _items-nowrap _align-middle"
		>
			<div class="feature-item__img" :class="`feature-item__img--color-${item.id}`">
				<img :src="require(`@/assets/img/landing/feature-item-${item.id}.svg`)" />
			</div>
			<div class="feature-item__content">
				<div class="feature-item__title color_title-black">{{ item.title }}</div>
				<div class="feature-item__text title-sm">{{ item.text }}</div>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	data() {
		return {
			points: [
				{
					id: 1,
					title: '10+',
					text: 'Источников данных'
				},
				{
					id: 2,
					title: '230+',
					text: 'Интернет-магазинов пользуются данными DATAFORCE'
				},
				{
					id: 3,
					title: '20+',
					text: 'Терабайт данных обрабатывает система'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.feature-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 45px;

	@media all and (max-width: 1090px) {
		gap: 24px;
	}

	@media all and (max-width: 910px) {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}

	@include tablets {
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px;
	}
	@include phones {
		max-width: 80vw;
		margin: auto;
	}

	.feature-item {
		gap: 24px;
		@media all and (max-width: 1090px) {
			gap: 18px;
		}
		@include phones {
			gap: 24px;
		}
		&__img {
			min-width: 120px;
			min-height: 120px;
			width: 120px;
			height: 120px;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			&--color-1 {
				background-color: rgba(255, 143, 0, .08);
			}

			&--color-2 {
				background-color: rgba(0, 200, 83, .08);
			}

			&--color-3 {
				background-color: rgba(126, 87, 194, .08);
			}

			@media all and (max-width: 1090px) {
				width: 100px;
				height: 100px;
				min-width: 100px;
				min-height: 100px;
			}

			@media all and (max-width: 910px) {
				width: 80px;
				height: 80px;
				min-width: 80px;
				min-height: 80px;

				img {
					width: 25px;
					height: 25px;
					object-fit: contain;
				}
			}

			@include phones {
				width: 120px;
				height: 120px;
				min-width: 120px;
				min-height: 120px;

				img {
					width: 42px;
					height: 42px;
					object-fit: contain;
				}
			}

			@media all and (max-width: 380px) {
				width: 80px;
				height: 80px;
				min-width: 80px;
				min-height: 80px;
				img {
					width: 24px;
					height: 24px;
					object-fit: contain;
				}
			}
		}

		&__title {
			font-family: 'Montserrat', Arial, Helvetica, sans-serif;
			font-weight: 700;
			line-height: 1.2;
			font-size: 48px;
			margin-bottom: 6px;

			@media all and (max-width: 1090px) {
				font-size: 38px;
			}

			@include phones {
				font-size: 48px;
			}
		}

		&__text {
			line-height: 140%;
			@media all and (max-width: 1090px) {
				font-size: 18px;
			}
			@include phones {
				font-size: 16px;
			}
		}
	}
}
</style>