<template>
	<div class="attention-slider-wrapper">
		<VueSlickCarousel
			class="attention-slider"
			:arrows="false"
			:dots="false"
			:infinite="true"
			:autoplay="true"
			:speed="2500"
			:autoplaySpeed="2500"
			:cssEase="'ease-in-out'"
			:slidesToShow="6"
			:responsive="[
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 410,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
			]"
			:pauseOnHover="true"
			:swipe="false"
		>
		<div class="attention-item" v-for="partner in partners" :key="partner.title">
			<img :src="getImgUrl(`partners/${partner.imgName}.svg`)" />
		</div>
		</VueSlickCarousel>
	</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
	name: 'ClientsSlider',
	components: { VueSlickCarousel },
  data () {
    return {
      partners: [
        {title: 'Ozon', imgName: 'ozon'},
        {title: 'Петрович', imgName: 'petrovich'},
        {title: 'СберМегаМаркет', imgName: 'sber'},
        {title: 'Farfetch', imgName: 'farfetch'},
        {title: 'Лента', imgName: 'lenta'},
        {title: 'Ашан', imgName: 'auchan'},
        {title: 'Эльдорадо', imgName: 'eldorado'},
        {title: 'Ситилинк', imgName: 'citylink'},
        {title: 'Все инструменты', imgName: 'vse_instrumenti'},
      ]
    }
  },
  methods: {
    getImgUrl (path) {
      return path ? require(`@/assets/img/landing/${path}`) : ''
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
  .attention-slider-wrapper {
    max-width: 100%;

		@include tablets {
			max-width: 90vw;
			margin: auto;
		}
  }
  .attention-slider {
    margin: 48px 0px;
    width: 100%;

		@include phones {
			margin-top: 0px;
			margin-bottom: 0;
		}

    .slick-track {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;

      .slick-slide {
        height: 100%;
      }
    }

    .attention-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 95px;
        max-height: 56px;
        object-fit: scale-down;

				@media all and (max-width: 410px) {
					max-height: 24px;
					max-width: 84px;
				}
      }
    }
  }
</style>