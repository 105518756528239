<template>
	<v-button
		:to="{ name: ROUTE_NAME.DEMO }"
		name="Записаться на демо"
		class="demo-btn"
		style="margin: 0 auto"
		dataforce
    size="large"
	/>
</template>

<script>
import ROUTE_NAME from '@/router/routeName'
export default {
	data() {
		return {
			ROUTE_NAME
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.demo-btn {
	width: 198px;
	height: 52px;
	z-index: 1;
  font-size: 14px !important;

	@include phones {
		width: 236px;
	}
}
</style>
