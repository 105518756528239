<template>
	<ul class="capabilities-list">
		<li
			v-for="item in capabilities"
			:key="item.id"
			class="capabilities-item cols _align-middle _items-nowrap"
			:class="item.imgPosition === 'right' && '_reverse'"
		>
			<div class="capabilities-item__img">
				<img :src="require(`@/assets/img/landing/capabilities/block-${item.id}.svg`)" />
			</div>
			<div class="capabilities-item__content cols _items-row">
				<div class="capabilities-item__icon" :class="`capabilities-item__icon--${item.id}`">
					<img :src="require(`@/assets/img/landing/capabilities/icon-${item.id}.svg`)" />
				</div>
				<h3 class="capabilities-item__title text-left title md-title line-md line-mobile-m" v-html="item.title"></h3>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	data() {
		return {
			capabilities: [
				{
					id: 1,
					title: 'Кастомная отчетность по&nbsp;всей воронке продаж',
					imgPosition: 'left'
				},
				{
					id: 2,
					title: 'Гибкая система сегментации по&nbsp;структуре каталога сайта',
					imgPosition: 'right'
				},
				{
					id: 3,
					title: 'Мониторинг спроса по&nbsp;рынку',
					imgPosition: 'left'
				},
				{
					id: 4,
					title: 'Оптимизация маркетинга и контента',
					imgPosition: 'right'
				},
				{
					id: 5,
					title: 'Аналитика транзакций в&nbsp;разрезе сегментов вашего каталога',
					imgPosition: 'left'
				},
				{
					id: 6,
					title: 'Конверсия с&nbsp;разбивкой по&nbsp;ГЕО',
					imgPosition: 'right'
				},
				{
					id: 7,
					title: 'Точки роста и контроль результатов',
					imgPosition: 'left'
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.capabilities-list {
	.capabilities-item {
		gap: 90px;
		justify-content: flex-start;
		&._reverse {
			flex-direction: row-reverse;
			@include tablets {
				flex-direction: column-reverse;
			}
		}
		@media all and (max-width: 1380px) {
			gap: 50px;
		}
		@include sm-desktops {
			gap: 40px;
		}
		@include tablets {
			gap: 30px;
			flex-direction: column-reverse;
			align-items: flex-start;
		}
		@include phones {
			gap: 24px;
		}

		&:not(:last-child) {
			margin-bottom: 92px;

			@include tablets {
				margin-bottom: 45px;
			}

			@include phones {
				margin-bottom: 48px;
			}
		}
		&__icon {
			align-self: flex-start;
		}
		&__title {
			max-width: 80%;
			@include sm-desktops {
				max-width: 100%;
			}
		}

		&__img {
			min-width: 50%;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&__item, &__img {
			flex: 1;
		}

		@include tablets {
			&__img {
				min-width: 90%;
				margin-bottom: 32px;
			}
			
			&__content {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 12px;
				flex-wrap: nowrap;
			}

			&__icon {
				display: flex;
				align-content: center;
				justify-content: center;

				img {
					width: 36px;
					height: 36px;
					object-fit: contain;
				}
			}

			&__title {
				text-align: left;
				margin-top: 0;
			}
		}

		@include phones {
			&__img {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 0;
				
				img {
					max-width: 90%;
					min-width: 320px;
				}
			}
		}
	}
}
</style>