<template>
	<div class="publications">
		<h3 class="title md-title md-title--mobile-big">Полезные публикации</h3>
		<div class="cards line-regular line_mobile-large">
			<Publication v-for="el in publications" :key="el.id" :data="el" />
		</div>
	</div>
</template>

<script>
import Publication from './components/publication.vue';

export default {
	components: {Publication},
	computed: {
		config () {
			return this.$store.state.config
		},
		publications () {
			return [
				{
					img: 'publication-1.svg',
					type: 'СТАТЬЯ',
					title: '5 точек роста eCommerce и оценка их реализации',
					link: 'https://vc.ru/marketing/584199-5-tochek-rosta-ecommerce-i-ocenka-ih-realizacii'
				},
				{
					img: 'publication-2.svg',
					type: 'ВИДЕО',
					title: 'MateMarketing 2022',
					link: 'https://www.youtube.com/playlist?list=PL1drwgqDrPDKEc1lVC1YRo8E-dryPE1MD'
				},
				{
					img: 'publication-3.svg',
					type: 'ПРЕЗЕНТАЦИЯ',
					title: 'Презентация MateMarketing',
					link: `${this.config.api.dataforce}/help/matemarketing.pdf`
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped src="./style.scss" />