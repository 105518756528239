<template>
	<component :is="data.to ? 'router-link' : 'a'" :href="data.link" :to="data.to" target="_blank" :class="['card', data.border && 'border']">
		<div class="card__img">
			<img :src="getImgUrl(`publications/${data.img}`)" />
		</div>
		<div class="card__body">
			<div class="card__text-wrapper">
				<span v-if="data.type" :class="['card__badge', badgeColor]">{{data.type}}</span>
				<span class="card__title">{{data.title}}</span>
			</div>
			<span class="card__date" v-if="data.date">{{ data.date }}</span>
			<v-button class="card__more-btn" v-if="data.moreBtn">{{ data.moreBtn }}</v-button>
		</div>
	</component>
</template>

<script>
export default {
	props: {
		data: {
			type: Object,
			default: ()=>({})
		}
	},
  computed: {
    badgeColor () {
      switch (this.data.type) {
        case 'ВЕБИНАР':
        case 'ПРЕЗЕНТАЦИЯ':
          return 'green'
        case 'СТАТЬЯ':
          return 'yellow'
        default:
          return ''
      }
    }
  },
  methods: {
    getImgUrl (path) {
      return path ? require(`@/assets/img/landing/${path}`) : ''
    }
  }
}
</script>

<style lang="scss" scoped src="./style.scss" />